import React, {useEffect, useRef} from "react";
import styles from "./ChatBody.module.css"
import {useAppSelector} from "../../../../app/hooks";
import {messagesSelector, requestStatusSelector, Role} from "../../chatSlice";
import {DotTyping} from "../DotTyping/DotTyping";

export const ChatBody: React.FC = () => {

    const messages = useAppSelector(messagesSelector)
    const requestStatus = useAppSelector(requestStatusSelector)
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [requestStatus])

    return <div className={styles.chatBodyWrapper}>
        {messages.map((message, index) => (
            <div key={index} className={styles.generalMessageWrapper}>
                <div
                    ref={messages.length - 1 === index ? messagesEndRef : undefined}
                    className={message.role === Role.USER ? styles.userMessageWrapper : styles.assistantMessageWrapper}>
                    <div key={index}
                         className={message.role === Role.USER ? styles.userMessage : styles.assistantMessage}>
                        {message.content ? <span className={styles.content}>{message.content}</span> : <DotTyping/>}
                    </div>
                </div>
            </div>
        ))}
    </div>
}
