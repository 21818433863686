import React from "react";
import {Products} from "../features/products/Products";
import {Chat} from "../features/chat/Chat";
import {InitialScreen} from "../features/initialScreen/InitialScreen";

export const Content: React.FC = () => {

    return <div className="content">
        <InitialScreen/>
        <div className="second-screen-wrapper">
            <Products/>
            <Chat/>
        </div>
    </div>
}
