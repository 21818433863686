import React from "react";
import styles from "./BestSellers.module.css"
import {BestSellerItem} from "../BestSellerItem/BestSellerItem";
import {useAppSelector} from "../../../../app/hooks";
import {otherProductsSelector} from "../../productsSlice";
import {mockedProducts, Product} from "../../mockedData";

interface BestSellersProps {
    onProductClick: (product: Product) => void
}

export const BestSellers: React.FC<BestSellersProps> = ({onProductClick}) => {

    let otherProducts = useAppSelector(otherProductsSelector);

    if (!otherProducts) {
        otherProducts = mockedProducts
    } else if (otherProducts.length < 3) {
        otherProducts = otherProducts.concat(mockedProducts)
    }

    return <div className={styles.bestSellersBox}>
        <span className={styles.title}>Other Best Sellers</span>
        <div className={styles.items}>
            {[...otherProducts!]?.sort((p1, p2) => p1.priority! - p2.priority!)
                .slice(0, 3)
                .map((product, key) => {
                    return <BestSellerItem key={key} product={product} onProductClick={onProductClick}/>
                })}
        </div>
    </div>
}
