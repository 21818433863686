import React from "react";
import styles from "./InnovativeProduct.module.css"

interface SuggestionProps {
    imagePath: string;
    text: string;
}

export const InnovativeProduct: React.FC<SuggestionProps> = ({imagePath, text}) => {

    return <div className={styles.wrapper}>
        <img
            className={styles.image}
            src={imagePath}
            alt={'img'}
        />
        <span className={styles.text}>{text}</span>
    </div>
}
