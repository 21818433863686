export interface Product {
    id: number;
    name: string;
    description: string;
    price?: string;
    imagePath?: string;
    specificDetails: string []
    priority?: number
}


export const mockedProducts: Product[] = [
    {
        id: 1,
        name: "BrightLink 1485Fi 1080p 3LCD Interactive Laser Display",
        description: "Bring collaboration to classrooms and meeting spaces with this interactive HD 1080p laser display. Create engaging and collaborative learning environments with the BrightLink 1485Fi 1080p1 3LCD interactive laser display. Providing 5,000 lumens of color and white brightness2 and a virtually maintenance-free 20,000-hour laser light source with no lamps3, this reliable, ultra bright interactive display supports easy reading, instruction and communication. The flexible 1485Fi projects large, 100\", 16:9 and super-wide, 120\", 16:6 images, offering up to 95 percent more interactive space vs. a 75\" flat panel. Designed for easy collaboration, the 1485Fi supports PC-free whiteboarding, printing, emailing and more. Plus, it easily hosts other network-connected BrightLink projectors4, allowing up to eight users to work on a single interactive canvas from multiple locations. Now with automatic image adjustment, simplified calibration and a single-cable, HDBaseT control pad, installation has never been easier",
        price: "3,560.00",
        specificDetails: [
            "Providing 5,000 lumens of color and white brightness",
            "Ultra bright interactive display supports easy reading",
            "Supports PC-free whiteboarding, printing, emailing and more"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/65ce66afa99879cb6b7347ba57705fb83b9b3d82/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=bl1485fi_hero_690x460"
    },
    {
        id: 2,
        name: "BrightLink 1480Fi 1080p 3LCD Interactive Laser Display",
        description: "Create dynamic learning environments with this 3LCD 1080p1 interactive laser display. Increase engagement and collaboration in classrooms and meeting spaces with the dynamic BrightLink 1480Fi 1080p1 3LCD interactive laser display. Designed with convenience in mind, this ultra bright, 5,000-lumen ultra short-throw projector offers easy installation and a virtually maintenance-free 20,000-hour laser light source with no lamps3. Offering a 16:9 display up to 100\", the 1480Fi provides 75 percent more interactive space than 75\" panels for a fully immersive display in meeting spaces and classrooms. With support for optional BrightLink touch modules, it allows up to eight users to work on a single interactive canvas. Plus, it comes with a host of PC-free features including collaborative whiteboard sharing, printing, emailing and more.",
        price: "3,245.00",
        specificDetails: [
            "Designed with convenience in mind",
            "5,000-lumen ultra short-throw projector",
            "Virtually maintenance-free 20,000-hour laser light source with no lamps3"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/a7314e0b857a15e076917617b8c14b75bd03ae0d/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=BrightLink-1480F-Angle_690x460"
    },
    {
        id: 3,
        name: "PowerLite L775U 3LCD Laser Projector with 4K Enhancement",
        description: "Deliver brilliant displays with remarkable brightness and 4K Enhancement. The versatile PowerLite L775U laser projector brings strikingly clear images to meeting rooms, classrooms and beyond. This conveniently lightweight, compact projector offers native WUXGA with 4K Enhancement1 and an incredible 7,000 lumens of brightness, providing smooth, ultra bright, detailed images—even up close. The projector’s sleek, black exterior seamlessly blends into black ceilings, making it ideal for museums and event spaces. Display images up to 500'' and switch between a variety of aspect ratios, from standard 16:10 to ultra wide 21:9 that provides for a more immersive experience—ideal for hybrid work environments as well as classrooms. The more information that needs to be displayed on a single screen, the more critical the need for a larger display and higher resolution. Simple to install, the PowerLite L775U is easy to operate from start to finish. Designed with convenience in mind, this projector features a 20,000-hour virtually maintenance-free laser light source and air filter. Plus, simplify complex multi-projector applications with the optional attachable PixAlign™ camera. Best-in-Class Color Brightness; 7,000 lumens WUXGA with 4K Enhancement1 for clear, detailed images 20,000-hour, virtually maintenance-free laser light source and air filter Picture up to 500\" diagonal in 16:10, plus ultra wide 16:6 and 21:9.",
        price: "4,549.00",
        specificDetails: [
            "Display images up to 500'' and switch between a variety of aspect ratios",
            "Ideal for hybrid work environments as well as classrooms",
            "Brings strikingly clear images to meeting rooms, museums and beyond"
        ],
        imagePath: "https://i8.amplience.net/i/epsonemear/l775u_b_std_03_png?fmt=auto&img404=missing_product&v=1"
    },
    {
        id: 4,
        name: "PowerLite L770U 3LCD Laser Projector with 4K Enhancement",
        description: "Deliver brilliant displays with remarkable brightness and 4K Enhancement.",
        price: "4,549.00",
        specificDetails: [
            "Incredible 7,000 lumens of brightness",
            "WUXGA with 4K Enhancement1 for clear, detailed images",
            "Ideal for hybrid work environments as well as classrooms"
        ],
        imagePath: "https://i8.amplience.net/i/epsonemear/l610w_wh_04_png?fmt=auto&img404=missing_product&v=1"
    },
    {
        id: 5,
        name: "PowerLite L730U Full HD WUXGA 3LCD Laser Projector",
        description: "Captivate your audience with bright, larger-than-life laser displays.",
        price: "3,799.00",
        specificDetails: [
            "Up to 7,000 lumens of equal Color and White Brightness",
            "Display from 50\" to 500\" diagonal (16:10) or ultra wide (16:6)",
            "Built-in tools for simplified installation"
        ],
        imagePath: "https://i8.amplience.net/i/epsonemear/l730u_w_std_03_png?fmt=auto&img404=missing_product&v=1"
    },
    {
        id: 6,
        name: "PowerLite L630U Full HD WUXGA 3LCD Laser Projector",
        description: "The ultra bright Full HD WUXGA projector for classrooms, meetings and more.",
        price: "3,149.00",
        specificDetails: [
            "Up to 6,200 lumens of equal Color and White Brightness",
            "Virtually maintenance-free 20,000-hour laser light source",
            "Display from 50\" to 500\" diagonal (16:10) or ultra wide (16:6)"
        ],
        imagePath: "https://i8.amplience.net/i/epsonemear/l730u_w_std_01_png?fmt=auto&img404=missing_product&v=1"
    },
    {
        id: 7,
        name: "PowerLite L570U 3LCD Laser Projector with 4K Enhancement",
        description: "Brilliant colors and stunning clarity in one compact projector.",
        price: "3,149.00",
        specificDetails: [
            "Best-in-Class Color Brightness; 5,200 lumens",
            "WUXGA with 4K Enhancement for clear, detailed images",
            "Picture up to 500\" diagonal in 16:10, plus ultra wide 16:6 and 21:9"
        ],
        imagePath: "https://i8.amplience.net/i/epsonemear/l610w_wh_04_png?fmt=auto&img404=missing_product&v=1"
    },
    {
        id: 8,
        name: "PowerLite L530U Full HD WUXGA 3LCD Laser Projector",
        description: "Share bright, easy-to-read displays for meetings, lectures and more with the PowerLite L530U laser projector.",
        price: "2,651.00",
        specificDetails: [
            "Up to 5,200 lumens of equal Color and White Brightness",
            "Full HD WUXGA support; accepts 4K signal",
            "Virtually maintenance-free 20,000-hour laser light source3"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/9d7dbc476f35fe3e477bf6dd34d707c4ed10176d/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=L530U_top-left_690x460"
    },
    {
        id: 9,
        name: "PowerLite L520U Full HD WUXGA 3LCD Long-throw Laser Projector",
        description: "Share larger-than-life Full HD WUXGA images with this versatile laser projector.",
        price: "2,549.00",
        specificDetails: [
            "Full HD WUXGA; accepts 4K input",
            "Virtually maintenance-free 20,000-hour laser light source",
            "Display from 50\" to 500\" diagonal (16:10) or ultra wide (16:6, 21:9)"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/8aef0134c5994ec78d36a710c912161802ca6651/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=L520U_headon_690x460"
    },
    {
        id: 10,
        name: "PowerLite 2250U Full HD WUXGA 3LCD Projector",
        description: "The bright WUXGA, Full HD projector with optional wireless networking.",
        price: "1,574.00",
        specificDetails: [
            "Native WUXGA resolution for displays up to 300\"",
            "Full HD 1080p support for true-to-life images, and optional wireless networking",
            "5,000 lumens of color brightness"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/1178d8f6e45202f7336318aa0cf63df400fa3c4d/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=EB-2250U_01"
    },
    {
        id: 11,
        name: "PowerLite L520W WXGA 3LCD Laser Projector",
        description: "Bring bright, easy-to-read displays to meeting rooms, lecture halls and more.",
        price: "2,039.00",
        specificDetails: [
            "Up to 5,200 lumens of equal Color and White Brightness",
            "Native WXGA resolution and 16:10 performance",
            "Up to 280\" screen size with split screen capability"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/c11fcea2f7b99d7f80bce8fd6ac3cdae104ae7d1/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=L520W_top-left_690x460"
    },
    {
        id: 12,
        name: "PowerLite 1288 Full HD 1080p Meeting Room Projector with Built-in Wireless and Miracast",
        description: "Bring ultra bright displays to meeting rooms with this wireless portable projector.",
        price: "855.00",
        specificDetails: [
            "PowerLite 1288 provides 4,000 lumens of color and white brightness",
            "Full HD 1080p resolution to display outstanding, high-quality images",
            "Ideal for a variety of business applications"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/f9c18ae072a1f6c7297b98b1cf1c5ca5522d29b8/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=PowerLite_1288_Product_01_Head-on_690x460"
    },
    {
        id: 13,
        name: "EpiqVision Mini EF11 Laser Projector",
        description: "Enjoy an epic viewing experience virtually anywhere with the Epson EpiqVision Mini EF11 Laser Projector",
        price: "799.99",
        specificDetails: [
            "Stunning Picture Quality up to 150\"",
            "Ready for Streaming",
            "Integrated Dual-Speaker Audio System"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/cd254dc8d28d3162a933f889480a763f77d9b64d/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=EF11_headon_690x460"
    },
    {
        id: 14,
        name: "Home Cinema 2200 3LCD Full HD 1080p Projector",
        description: "Built from the ground up to deliver an exceptionally immersive viewing experience, the Epson® Home Cinema 2200 displays vivid, true-to-life content with Best-in-Class Color Brightness and advanced 3-chip, 3LCD technology",
        price: "899.99",
        specificDetails: [
            "Amazing dynamic contrast ratio",
            "Versatile connectivity",
            "Convenient audio output"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/29957861e89cbe973fbeabbbb37e34bdd54d6417/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=HC2200-%20V11HA88020_top-headon_690x460"
    },
    {
        id: 15,
        name: "Home Cinema 5050UB 4K PRO-UHD®1 3-Chip HDR2 Projector",
        description: "The Epson® Home Cinema 5050UB 4K PRO-UHD1 projector delivers an amazing 4K home theater experience for the DIY enthusiast",
        price: "2,999.99",
        specificDetails: [
            "Epson UltraBlack™ Technology",
            "Precision HDR2 Adjustment",
            "Extreme Color Gamut"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/a70e1437e7382c9f2edeba3baa2c22964c46d27c/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=hc5050ub_hero_v3_690x460"
    },
    {
        id: 16,
        name: "EB-PQ2008W 8,000-Lumen 4K 3LCD Laser Projector",
        description: "Leveraging our 4K Crystal Motion technology, thermo-control panel and double micro-lens array, the EB-PQ2008W laser projector delivers stunning 4K resolution on-screen",
        price: "-",
        specificDetails: [
            "8,000 lumens of equal white and color brightness",
            "4K Crystal Motion technology",
            "High native contrast for a realistic display"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/2c1259ac93310b8ad1c3ef62ff54ca5cd30c2777/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=EB-PQ2008W_top-left_690x460"
    },
    {
        id: 17,
        name: "Pro L30002UNL Laser WUXGA 3LCD Projector with 4K Enhancement",
        description: "Epson’s brightest projector, the Pro L30002UNL offers 30,000 lumens of color and white brightness2, plus native WUXGA resolution with 4K Enhancement Technology1, making it the perfect choice for live events",
        price: "ffffffffffff",
        specificDetails: [
            "High-aperture Epson® 3LCD, 3-chip technology",
            "WUXGA with 4K Enhancement1 (1920 x 1200 x 2)",
            "Maintenance-free operation for up to 20,000 hours"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/105099b4e1d9b0dd14ec7e98e1e11da0f3377f61/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=Pro_L30002UNL_Product_01_Head-on_690x460"
    },
    {
        id: 18,
        name: "EB-PU1007B WUXGA 3LCD Laser Projector with 4K Enhancement",
        description: "The EB-PU1007B offers larger-than-life images from a sleek, powerful projector that’s easy to install",
        price: "-",
        specificDetails: [
            "Mount sold separately",
            "7,000 lumens color/white brightness",
            "Native WUXGA with 4K Enhancement Technology"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/e2b2e3c0b96c744af9a9eb462adeb38495c65322/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=EB-PU1007B_top-left_690x460"
    },
    {
        id: 19,
        name: "Home Cinema 2350 4K PRO-UHD 3-Chip 3LCD Smart Streaming Projector",
        description: "Enjoy an immersive, big screen home theater experience with the Home Cinema 2350 Smart Streaming Projector",
        price: "1,299.99",
        specificDetails: [
            "Multiple audio options",
            "Integrated 10 W bass-reflex speaker",
            "Bluetooth® wireless audio device"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/57dd85d965d80b94e35e2a9b65e839e426d6dd0c/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=HC-2350_top-right_690x460"
    },
    {
        id: 20,
        name: "EpiqVision Ultra LS500 Ultra Short Throw Laser Projector - Certified ReNew",
        description: "With an immersive 4K HDR2 viewing experience, the Epson EpiqVision Ultra LS500 ultra-short throw laser projector represents a new generation of digital projection that redefines the entertainment experience by offering a stunning picture up to 130",
        price: "2,699.99",
        specificDetails: [
            "Immersive 130\" Display",
            "New Ultra-Short Throw Laser-Array Technology",
            "4K PRO-UHD1 Projection Technology"
        ],
        imagePath: "https://mediaserver.goepson.com/ImConvServlet/imconv/5f5afd79be2544888fea71920dd2a16486b6468c/1200Wx1200H?use=banner&hybrisId=B2C&assetDescr=ls500-white_right-angle_690x460"
    }
]

export const getProductById = (productId: number) => {
    return mockedProducts.find(p => p.id == productId);
}

export const getImagePathByProductId = (productId: number) => {
    return getProductById(productId)?.imagePath;
}
