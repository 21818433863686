import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {mockedProducts, Product} from "./mockedData";

export interface ProductResponse {
    productId: number;
    priority: number;
}

export interface MetaInfoResponse {
    isUserRequestedSpecificProduct: boolean;
    showImage: boolean;
    showComparisonTable: boolean;
    tableContent: string;
    products?: ProductResponse[];
}

export interface ProductsState {
    showProducts: boolean;
    rawMetaInfo: string;
    isUserRequestedSpecificProduct?: boolean;
    showImage?: boolean;
    showComparisonTable?: boolean;
    tableContent?: []
    topProduct?: Product;
    otherProducts?: Product[];
}

const initialState: ProductsState = {
    showProducts: false,
    rawMetaInfo: ''
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        showProducts: (state, {payload}: PayloadAction<boolean>) => {
            state.showProducts = payload;
        },
        appendRawMetaInfo: (state, {payload}: PayloadAction<string>) => {
            state.rawMetaInfo += payload;
        },
        processRawProducts: (state) => {
            const rawMetaInfo = state.rawMetaInfo
            try {
                const metaInfo = JSON.parse(rawMetaInfo) as MetaInfoResponse;

                state.showImage = metaInfo.showImage;
                state.showComparisonTable = metaInfo.showComparisonTable;

                // @ts-ignore
                state.tableContent = parseCSV(metaInfo.tableContent);

                const products = metaInfo.products;
                const isUserRequestedSpecificProduct = metaInfo.isUserRequestedSpecificProduct;
                state.isUserRequestedSpecificProduct = isUserRequestedSpecificProduct;
                if (isUserRequestedSpecificProduct) {
                    const topProductId = products?.find(p => p.priority === 1)!.productId;
                    state.topProduct = mockedProducts.find(p => p.id === topProductId);

                    const otherProductsIdMap = new Map(products?.filter(p => p.priority !== 1)
                        .map(p => [p.productId, p.priority]));

                    state.otherProducts = [...mockedProducts].filter(p => otherProductsIdMap.has(p.id))
                } else {
                    const productIdMap = new Map(products?.map(p => [p.productId, p.priority]));
                    state.otherProducts = [...mockedProducts].filter(p => productIdMap.has(p.id))
                }
                state.showProducts = true;
            } catch (e) {
                console.log("Failed to process metaInfo; e:", e)
            }
        },
        clearRawMetaInfo: (state) => {
            state.rawMetaInfo = '';
        },
    },
    extraReducers: (builder) => {
    },
});

export const {
    showProducts,
    clearRawMetaInfo,
    appendRawMetaInfo,
    processRawProducts
} = productsSlice.actions;

export const showProductsSelector = (state: RootState): boolean => state.products.showProducts;
export const isUserRequestedSpecificProductSelector = (state: RootState): boolean | undefined => state.products.isUserRequestedSpecificProduct;
export const topProductSelector = (state: RootState): Product | undefined => state.products.topProduct;
export const otherProductsSelector = (state: RootState): Product[] | undefined => state.products.otherProducts;
export const showComparisonTableSelector = (state: RootState): boolean | undefined => state.products.showComparisonTable;
export const tableContentSelector = (state: RootState): [] | undefined => state.products.tableContent;

export default productsSlice.reducer;

const parseCSV = (csvText: string) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split("&&");
    const parsedData = [];

    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split("&&");

        if (currentLine.length === headers.length) {
            const row = {};
            for (let j = 0; j < headers.length; j++) {
                // @ts-ignore
                row[headers[j].trim()] = currentLine[j].trim();
            }
            parsedData.push(row);
        }
    }

    return parsedData
}
