import React from "react";
import styles from "./Suggestion.module.css"

interface SuggestionProps {
    text: string;
    onClick: (text: string) => void
}

export const Suggestion: React.FC<SuggestionProps> = ({text, onClick}) => {

    return <div className={styles.suggestionWrapper} onClick={e => onClick(text)}>
        <span className={styles.suggestionText}>{text}</span>
    </div>
}
