import {Action, configureStore, ThunkAction} from '@reduxjs/toolkit';
import chatReducer from '../features/chat/chatSlice';
import productsReducer from '../features/products/productsSlice';
import initialScreenReducer from '../features/initialScreen/initialScreenSlice';
import counterReducer from '../features/counter/counterSlice';

export const store = configureStore({
    reducer: {
        chat: chatReducer,
        products: productsReducer,
        counter: counterReducer,
        initialScreen: initialScreenReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
