import React, {useState} from "react";
import styles from "./Quote.module.css"
import {useAppSelector} from "../../../../app/hooks";
import {topProductSelector} from "../../productsSlice";

interface QuoteProps {
    setShowQuote: (showQuoteForm: boolean) => void
}

export const Quote: React.FC<QuoteProps> = ({setShowQuote}) => {

    const topProduct = useAppSelector(topProductSelector);
    const [showQuoteDone, setShowQuoteDone] = useState<boolean>(false);

    const handleQuoteBtnClick = () => {
        setShowQuoteDone(true);
        setTimeout(() => {
            setShowQuote(false);
        }, 3000)
    }

    return <div className={styles.primaryProductBox}>
        <span className={styles.title}>Epson Projectors: Recommended</span>
        <div className={styles.bottomSection}>
            <div className={styles.leftSection}>
                <img className={styles.imagePlaceholder}
                     src={topProduct?.imagePath ? topProduct?.imagePath : './image-placeholder.svg'} alt={'product'}/>
                <div className={styles.productInfoWrapper}>
                    <span className={styles.productName}>
                        {topProduct?.name}
                    </span>
                    <div className={styles.productPriceWrapper}>
                        <span className={styles.msrpSpan}>MSRP</span>
                        <span className={styles.productPrice}>${topProduct?.price}</span>
                    </div>
                </div>
            </div>
            {!showQuoteDone && <div className={styles.rightSection}>
                <div className={styles.formTitleWrapper}>
                    <span className={styles.formTitle}>Get a Quote</span>
                    <span className={styles.formSubTitle}>Please complete the following form</span>
                </div>
                <input className={styles.input} placeholder={'Full Name'}/>
                <input className={styles.input} placeholder={'Email Address'}/>
                <input className={styles.input} placeholder={'Phone'}/>
                <textarea className={styles.inputMessage} placeholder={'Message'}/>
                <div
                    className={styles.quoteButton}
                    onClick={e => handleQuoteBtnClick()}>
                    <span className={styles.quoteButtonTitle}>Get a Quote</span>
                    <img className={styles.quoteButtonImage} src={"./play-icon-white.svg"} alt={"buy"}/>
                </div>
            </div>}
            {showQuoteDone && <div className={styles.rightSection}>
                <div className={styles.formTitleWrapper}>
                    <span className={styles.formTitle}>Get a Quote</span>
                    <span className={styles.formSubTitle}>Please complete the following form</span>
                </div>
                <div className={styles.finalWrapper}>
                    <span className={styles.finalTitle}>Thank you</span>
                    <span className={styles.finalSubTitle}>An Epson export will be contacting you soon.</span>
                </div>
            </div>}
        </div>
    </div>
}
