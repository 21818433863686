import React from "react";
import styles from "./AllOptions.module.css"
import {BestSellerItem} from "../BestSellerItem/BestSellerItem";
import {useAppSelector} from "../../../../app/hooks";
import {otherProductsSelector} from "../../productsSlice";
import {Product} from "../../mockedData";

interface AllOptionsProps {
    onProductClick: (product: Product) => void
}

export const AllOptions: React.FC<AllOptionsProps> = ({onProductClick}) => {

    const otherProducts = useAppSelector(otherProductsSelector);

    return <div className={styles.bestSellersBox}>
        <span className={styles.title}>Best Sellers</span>
        <div className={styles.items}>
            {[...otherProducts!]?.sort((p1, p2) => p1.priority! - p2.priority!)
                .slice(0, 3)
                .map((product, key) => {
                    return <BestSellerItem key={key} product={product} onProductClick={onProductClick}/>
                })}
        </div>
        <div className={styles.items}>
            {[...otherProducts!]?.sort((p1, p2) => p1.priority! - p2.priority!)
                .slice(3, 6)
                .map((product, key) => {
                    return <BestSellerItem key={key} product={product} onProductClick={onProductClick}/>
                })}
        </div>
    </div>
}
