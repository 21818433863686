import React, {useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    isUserRequestedSpecificProductSelector,
    showComparisonTableSelector,
    showProductsSelector,
    tableContentSelector,
    topProductSelector
} from "./productsSlice";
import styles from "./Products.module.css"
import "../../transitionStyles.css"
import {PrimaryProduct} from "./components/PrimaryProduct/PrimaryProduct";
import {BestSellers} from "./components/BestSellers/BestSellers";
import {AllOptions} from "./components/AllOptions/AllOptions";
import {Product} from "./mockedData";
import {getChatResponse, RequestStatus, requestStatusSelector, updateUserMessage} from "../chat/chatSlice";
import {ComparisonTable} from "./components/ComparisonTable/ComparisonTable";
import {TopProduct} from "./components/TopProduct/TopProduct";
import {LottieFileAnimation} from "./components/LottieFileAnimation/LottieFileAnimation";
import {Quote} from "./components/Quote/Quote";

export const Products: React.FC = () => {

    const dispatch = useAppDispatch();
    const requestStatus = useAppSelector(requestStatusSelector);
    const showProducts = useAppSelector(showProductsSelector);
    const topProduct = useAppSelector(topProductSelector);
    const showComparisonTable = useAppSelector(showComparisonTableSelector);
    const tableContent = useAppSelector(tableContentSelector);
    const isUserRequestedSpecificProduct = useAppSelector(isUserRequestedSpecificProductSelector);
    const animationNode = useRef(null);
    const productsNode = useRef(null);
    const [showQuote, setShowQuote] = useState<boolean>(false);

    const handleProductClick = (product: Product) => {
        dispatch(updateUserMessage(`I am interested in ${product.name}`));
        dispatch(getChatResponse())
    }

    const getContent = () => {
        if (showComparisonTable) {
            return <ComparisonTable
                csvData={tableContent!}
                onRowClick={handleProductClick}
            />
        } else if (isUserRequestedSpecificProduct) {
            if (topProduct?.id === 1) {
                return <TopProduct
                    showQuote={showQuote}
                    setShowQuote={setShowQuote}
                    onProductClick={handleProductClick}
                />
            } else {
                return <>
                    {showQuote ? <Quote setShowQuote={setShowQuote}/> : <PrimaryProduct setShowQuote={setShowQuote}/>}
                    <BestSellers onProductClick={handleProductClick}/>
                </>
            }
        } else {
            return <AllOptions onProductClick={handleProductClick}/>
        }
    }

    return <><CSSTransition
        in={!showProducts && requestStatus !== RequestStatus.NONE}
        nodeRef={animationNode}
        timeout={0}
        unmountOnExit
        classNames="customTransition"
    >
        <div
            ref={animationNode}
            className={styles.animationWrapper}
        >
            <LottieFileAnimation/>
        </div>
    </CSSTransition>
        <CSSTransition
            in={showProducts}
            nodeRef={productsNode}
            timeout={300}
            unmountOnExit
            classNames="customTransition"
        >
            <div
                ref={productsNode}
                className={styles.productsWrapper}
            >
                {getContent()}
            </div>
        </CSSTransition>
    </>
}
