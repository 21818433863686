import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';

export interface InitialScreenState {
    showInitialScreen: boolean;
}

const initialState: InitialScreenState = {
    showInitialScreen: true,
};

export const initialScreenSlice = createSlice({
    name: 'initialScreen',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        hideInitialScreen: (state) => {
            state.showInitialScreen = false;
        },
    },
    extraReducers: (builder) => {
    },
});

export const {
    hideInitialScreen
} = initialScreenSlice.actions;

export const showInitialScreenSelector = (state: RootState) => state.initialScreen.showInitialScreen;

export default initialScreenSlice.reducer;
