import React from "react";
import styles from "./BestSellerItem.module.css"
import {Product} from "../../mockedData";

interface BestSellerItemProps {
    product: Product;
    onProductClick: (product: Product) => void;
}

export const BestSellerItem: React.FC<BestSellerItemProps> = ({product, onProductClick}) => {

    return <div className={styles.bestSellerItemBox}
                onClick={e => onProductClick(product)}>
        <img className={styles.imagePlaceholder}
             src={product.imagePath ? product.imagePath : './image-placeholder.svg'} alt={'product'}/>
        <div className={styles.productInfoWrapper}>
                    <span className={styles.productName}>
                        {product.name}
                    </span>
            <div className={styles.productPriceWrapper}>
                <span className={styles.msrpSpan}>MSRP</span>
                <span className={styles.productPrice}>${product.price}</span>
            </div>
            <div className={styles.quoteButton}>
                <span className={styles.quoteButtonTitle}>Get a Quote</span>
            </div>
        </div>
    </div>
}
