import React from "react";
import styles from "./ChatBottom.module.css"
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {
    getChatResponse,
    messageSelector,
    RequestStatus,
    requestStatusSelector,
    updateUserMessage
} from "../../chatSlice";

export const ChatBottom: React.FC = () => {

    const dispatch = useAppDispatch();
    const message = useAppSelector(messageSelector);
    const requestStatus = useAppSelector(requestStatusSelector);

    const sendMessage = () => {
        if (message && message.trim() && requestStatus !== RequestStatus.PENDING) {
            dispatch(getChatResponse())
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    }

    return <div className={styles.chatBottomWrapper}>
        <span className={styles.text}>Powered by Genjo.ai</span>
        <div className={styles.inputWrapper}>
            <input className={styles.input}
                   placeholder={"Ask Me Anything"}
                   value={message}
                   onKeyDown={handleKeyDown}
                   onChange={e => dispatch(updateUserMessage(e.target.value))}/>
            <img className={styles.sendIcon}
                 alt={'send'}
                 src="/gray-send-icon.svg"
                 onClick={sendMessage}
            />
        </div>
    </div>
}
