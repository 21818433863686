import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {appendRawMetaInfo, clearRawMetaInfo, processRawProducts, showProducts} from "../products/productsSlice";

const BASE_URL = window.location.hostname === 'localhost' ? 'http://localhost:8090' : 'https://epson.genjo.ai'

export enum RequestStatus {
    PENDING,
    FULFILLED,
    NONE
}

export enum Role {
    USER = "user",
    ASSISTANT = "assistant",
}

export interface ChatMessage {
    role: Role;
    content: string;
}

export interface ChatState {
    showChat: boolean;
    message: string;
    messages: ChatMessage[];
    requestStatus: RequestStatus;
}

const initialState: ChatState = {
    showChat: false,
    message: "",
    messages: [],
    requestStatus: RequestStatus.NONE
};

export const getChatResponse = createAsyncThunk<void, undefined, { state: RootState }>(
    'chat/getChatResponse',
    async (_payload, {getState, dispatch}) => {
        dispatch(appendUserMessage())
        const state = getState();
        let messages = state.chat.messages;
        if (messages && messages.length) {
            dispatch(setRequestStatus(RequestStatus.PENDING))
            dispatch(createEmptyAssistantMessage())
            dispatch(showProducts(false))
            const response = await fetch(`${BASE_URL}/api/chat/generate_message/stream`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/event-stream'
                },
                body: JSON.stringify({messages}),
            })
            if (response.body) {
                dispatch(clearRawMetaInfo())
                const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
                let isMetaInfo = true
                while (true) {
                    let {value, done} = await reader.read();
                    if (done) break;
                    value = (value || '').replaceAll('data: ', '')
                        .replaceAll("\n", "")
                        .replaceAll("\r", "")
                    if (!value.includes(': ping')) {
                        if (isMetaInfo) {
                            if (value.includes('###')) {
                                isMetaInfo = false
                                const indexOfSeparator = value.indexOf('###')
                                dispatch(appendRawMetaInfo(value.substring(0, indexOfSeparator)))
                                dispatch(processRawProducts())
                                dispatch(appendChunkToLastAssistantMessage(value.substring(indexOfSeparator + 3)))
                            } else {
                                dispatch(appendRawMetaInfo(value))
                            }
                        } else {
                            dispatch(appendChunkToLastAssistantMessage(value))
                        }
                    }
                }
                dispatch(setRequestStatus(RequestStatus.FULFILLED))
            }
        } else {
            throw new Error("Failed to fetch chat response. No messages")
        }
    }
);

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        showChat: (state) => {
            state.showChat = true;
        },
        updateUserMessage: (state, {payload}: PayloadAction<string>) => {
            state.message = payload;
        },
        appendUserMessage: (state) => {
            const newUserMessage = {
                role: Role.USER,
                content: state.message
            }
            if (state.messages) {
                state.messages = [...state.messages, newUserMessage]
            } else {
                state.messages = [newUserMessage]
            }
            state.message = ''
        },
        createEmptyAssistantMessage: (state) => {
            const newMessage = {
                role: Role.ASSISTANT,
                content: ""
            }
            if (state.messages) {
                state.messages = [...state.messages, newMessage]
            } else {
                state.messages = [newMessage]
            }
        },
        appendChunkToLastAssistantMessage: (state, {payload}: PayloadAction<string>) => {
            const lastMessage = state.messages.pop()
            if (lastMessage && lastMessage.role === Role.ASSISTANT) {
                lastMessage.content = lastMessage.content + payload
                state.messages = [...state.messages, lastMessage]
            } else {
                throw new Error("Something went wrong")
            }
        },
        setRequestStatus: (state, {payload}: PayloadAction<RequestStatus>) => {
            state.requestStatus = payload
        },
    },
    extraReducers: (builder) => {
    },
});

export const {
    showChat,
    setRequestStatus,
    appendUserMessage,
    updateUserMessage,
    createEmptyAssistantMessage,
    appendChunkToLastAssistantMessage,
} = chatSlice.actions;

export const messageSelector = (state: RootState): string => state.chat.message;
export const showChatSelector = (state: RootState): boolean => state.chat.showChat;
export const messagesSelector = (state: RootState): ChatMessage[] => state.chat.messages;
export const requestStatusSelector = (state: RootState): RequestStatus => state.chat.requestStatus;

export default chatSlice.reducer;
