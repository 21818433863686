import React from "react";
import styles from "./PrimaryProduct.module.css"
import {useAppSelector} from "../../../../app/hooks";
import {topProductSelector} from "../../productsSlice";

interface PrimaryProductProps {
    setShowQuote: (showQuote: boolean) => void;
}

export const PrimaryProduct: React.FC<PrimaryProductProps> = ({setShowQuote}) => {

    const topProduct = useAppSelector(topProductSelector)

    return <div className={styles.primaryProductBox}>
        <span className={styles.title}>Epson Projectors: Recommended</span>
        <div className={styles.bottomSection}>
            <img className={styles.imagePlaceholder}
                 src={topProduct?.imagePath ? topProduct?.imagePath : './image-placeholder.svg'} alt={'product'}/>

            <div className={styles.productInfoWrapper}>
                    <span className={styles.productName}>
                        {topProduct?.name}
                    </span>
                <div className={styles.productPriceWrapper}>
                    <span className={styles.msrpSpan}>MSRP</span>
                    <span className={styles.productPrice}>${topProduct?.price}</span>
                </div>
                <div className={styles.productDescription}>
                    <ul>
                        {topProduct?.specificDetails.map((info, key) => {
                            return <li key={key}>{info}</li>
                        })}
                    </ul>
                </div>
                <div
                    className={styles.quoteButton}
                    onClick={e => setShowQuote(true)}>
                    <span className={styles.quoteButtonTitle}>Get a Quote</span>
                    <img className={styles.quoteButtonImage} src={"./play-icon-white.svg"} alt={"buy"}/>
                </div>
            </div>
        </div>
    </div>
}
