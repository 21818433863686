import React from "react";
import styles from "./ComparisonTable.module.css";
import {getImagePathByProductId, getProductById, Product} from "../../mockedData";

interface ComparisonTableProps {
    csvData: []
    onRowClick: (product: Product) => void
}

export const ComparisonTable: React.FC<ComparisonTableProps> = ({csvData, onRowClick}) => {

    // @ts-ignore
    const headers = csvData.length > 0 ? Object.keys(csvData[0]).filter(h => h.toLowerCase() !== 'description') : [];

    const handleRowClick = (productId?: number) => {
        const product = getProductById(productId || -1)
        if (product) {
            onRowClick(product)
        }
    }

    return <table className={styles.tableStyle}>
        <thead>
        <tr>
            {headers.map((header, index) => (
                <th key={index} className={styles.tableHeaderStyle}>
                    {header !== 'productId' ? header : ''}
                </th>
            ))}
        </tr>
        </thead>
        <tbody>
        {csvData.map((row, index) => (
            <tr key={index}>
                {headers.map((header, columnIndex) => (
                    <td
                        key={columnIndex}
                        onClick={e => handleRowClick(row['productId'] as number)}
                        className={styles.tableCellStyle}>
                        {header !== 'productId' ? row[header] :
                            <img className={styles.image} src={getImagePathByProductId(row[header])} alt={'product'}/>}
                    </td>
                ))}
            </tr>
        ))}
        </tbody>
    </table>
}
