import React, {useRef} from "react";
import {CSSTransition} from "react-transition-group";
import {useAppSelector} from "../../app/hooks";
import {showChatSelector} from "./chatSlice";
import styles from "./Chat.module.css"
import "../../transitionStyles.css"
import {ChatHeader} from "./components/ChatHeader/ChatHeader";
import {ChatBottom} from "./components/ChatBottom/ChatBottom";
import {ChatBody} from "./components/ChatBody/ChatBody";

export const Chat: React.FC = () => {

    const showChat = useAppSelector(showChatSelector)
    const chatNode = useRef(null)

    return <CSSTransition
        in={showChat}
        nodeRef={chatNode}
        timeout={300}
        unmountOnExit
        classNames="customTransition"
    >
        <div ref={chatNode}>
            <div className={styles.chatWrapper}>
                <ChatHeader/>
                <ChatBody/>
                <ChatBottom/>
            </div>
            <div className={styles.chatPlaceholder}/>
        </div>
    </CSSTransition>
}
