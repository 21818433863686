import React from "react";
import styles from './LottieFileAnimation.module.css'
// @ts-ignore
import Lottie from 'react-lottie'
import animationData from './animation.json';

export const LottieFileAnimation: React.FC = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return <div className={styles.animationWrapper}>
        <Lottie
            options={defaultOptions}
        />
        <div className={styles.titleWrapper}>
            <span className={styles.title}>Personalizing</span>
            <span className={styles.title}>Your experience...</span>
        </div>
    </div>
}
