import React, {useRef} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {hideInitialScreen, showInitialScreenSelector} from "./initialScreenSlice";
import styles from "./InitialScreen.module.css"
import {getChatResponse, messageSelector, showChat, updateUserMessage} from "../chat/chatSlice";
import {CSSTransition} from "react-transition-group";
import "../../transitionStyles.css"
import {Suggestion} from "./Option/Suggestion";
import {InnovativeProduct} from "./InnovativeProduct/InnovativeProduct";

export const InitialScreen: React.FC = () => {

    const dispatch = useAppDispatch();
    const initialScreenNode = useRef(null);
    const showInitialScreen = useAppSelector(showInitialScreenSelector);
    const message = useAppSelector(messageSelector);

    const handleSend = () => {
        dispatch(getChatResponse())
        dispatch(showChat())
        dispatch(hideInitialScreen())
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && message && message.trim()) {
            handleSend();
        }
    }

    const handleSuggestionClick = (text: string) => {
        dispatch(updateUserMessage(text))
        handleSend()
    }

    return <CSSTransition
        in={showInitialScreen}
        nodeRef={initialScreenNode}
        timeout={300}
        unmountOnExit
        classNames="customTransition"
    >
        <div className={styles.initialScreenWrapper}>
            <div className={styles.wrapper}>
                    <span className={styles.title}>
                        What can I help you with?
                    </span>
                <span className={styles.subTitle}>
Find your perfect Epson projector effortlessly with our AI-powered tool, designed to match your specific needs and environment with the ideal visual solution. Experience the future of personalized projection technology, where clarity meets convenience                    </span>
                <div className={styles.inputWrapper}>
                    <input className={styles.input}
                           placeholder={'Looking for a projector...'}
                           value={message}
                           onKeyDown={handleKeyDown}
                           onChange={e => dispatch(updateUserMessage(e.target.value))}
                    />
                    <div className={styles.sendButton} onClick={handleSend}>
                        <img className={styles.sendIcon} src="/gray-send-icon.svg" alt={'send'}/>
                    </div>
                </div>
                <div className={styles.suggestionWrapper}>
                    <Suggestion text={'Looking for the perfect projector for my classroom'}
                                onClick={handleSuggestionClick}/>
                    <Suggestion text={'Looking for budget friendly projector'} onClick={handleSuggestionClick}/>
                    <Suggestion text={'Show me good projector for gaming'} onClick={handleSuggestionClick}/>
                </div>
            </div>
            <div className={styles.innovativeProductsWrapper}>
                <span className={styles.innovativeProductsText}>Innovative Products You Can Count On</span>
                <div className={styles.innovativeProducts}>
                    <InnovativeProduct
                        imagePath={'./p-2.png'}
                        text={'Thermal Receipt Printer'}
                    />
                    <InnovativeProduct
                        imagePath={'./p-6.png'}
                        text={'Label Printers'}
                    />
                    <InnovativeProduct
                        imagePath={'./p-3.png'}
                        text={'Kiosk & Self-Checkout'}
                    />
                    <InnovativeProduct
                        imagePath={'./p-5.png'}
                        text={'Back Office Printers'}
                    />
                    <InnovativeProduct
                        imagePath={'./p-4.png'}
                        text={'Document Imaging'}
                    />
                    <InnovativeProduct
                        imagePath={'./p-1.png'}
                        text={'Projection Solutions'}
                    />
                </div>
            </div>
        </div>
    </CSSTransition>
}
