import React from "react";
import styles from "./TopProduct.module.css"
import {PrimaryProduct} from "../PrimaryProduct/PrimaryProduct";
import {BestSellers} from "../BestSellers/BestSellers";
import {Product} from "../../mockedData";
import {Quote} from "../Quote/Quote";

interface TopProductProps {
    showQuote: boolean
    setShowQuote: (showQuote: boolean) => void
    onProductClick: (product: Product) => void
}

export const TopProduct: React.FC<TopProductProps> = ({showQuote, setShowQuote, onProductClick}) => {

    return <div className={styles.primaryProductBox}>
        {showQuote ? <Quote setShowQuote={setShowQuote}/> : <PrimaryProduct setShowQuote={setShowQuote}/>}
        <div className={styles.contentWrapper}>
            <iframe
                className={styles.video}
                src={'https://www.youtube.com/embed/ARtUl1hIOyw'}
                title={'YouTube video'}
                allowFullScreen={true}
            />
        </div>
        <BestSellers onProductClick={onProductClick}/>
    </div>
}
